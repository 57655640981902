
import { mapState } from 'vuex'
export default {
  name: 'Experts',
  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      isConsultant: false,
    }
  },
  created() {
    if (!process.client) {
      return
    }
  },
  mounted() {
    if (
      this.currentUser &&
      this.currentUser.role.id === 4
    ) {
      this.isConsultant = true
    }
  },
  computed: {
    ...mapState({
      currentUser: (state) => state.auth.user,
      isLoggedIn: (state) => state.auth.loggedIn,
    }),
  },
  watch: {
    currentUser() {
      if (
        this.currentUser &&
        this.currentUser.role.id === 4
      ) {
        this.isConsultant = true
      } else {
        this.isConsultant = false
      }
    },
  },
  methods: {
    checkLocalizations (item) {
      if ( item.length > 0 && item[0].locale === this.$i18n.locale) {
        return item[0].title;
      } else if(item.length > 0 && item[0].locale !== this.$i18n.locale) {
        return item[0].localizations.find((el) => el.locale === this.$i18n.locale).title
      } else return ''
    },
    toGetConsultation() {
      if (this.isLoggedIn) {
        this.$store
          .dispatch('getChatrooms', {
            populate: '*',
            'filters[$and][0][consultant][id]': this.data.id,
            'filters[$and][1][user][id]': this.currentUser.id,
            'filters[$and][2][isCompleted][$ne]': true,
          })
          .then((res) => {
            if (res.length > 0) {
              this.$router.push({
                path: this.localePath('/chats'),
                query: { room_id: res[0].id, consultant_id: this.data.id },
              })
            } else {
              this.$router.push({
                path: this.localePath('/chats'),
                query: { room_id: 'new', consultant_id: this.data.id },
              })
            }
          })
      } else {
        this.$router.push({
          path: this.localePath('/login'),
          query: {
            consultant: this.data.id,
            from: 'consultant',
          },
        })
      }
    },
  },
}
