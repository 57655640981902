
import { mapGetters, mapState } from 'vuex'
import registeModal from '../../components/modals/register.vue'
import expertsSwiper from '~/components/swipers/experts-swiper.vue'
import NewsSwiper from '~/components/swipers/news-swipers/new-swipers'
import PriceSwiper from '~/components/swipers/price-swiper.vue'
import PartnersSwiper from '~/components/swipers/partners-swiper.vue'
// import YandexMap from '~/components/core/yandex-map.vue'
import Faq from '~/components/Faq.vue'
import MainLoading from '~/components/main-loading'
export default {
  auth: false,
  components: {
    MainLoading,
    // YandexMap,
    expertsSwiper,
    PriceSwiper,
    NewsSwiper,
    PartnersSwiper,
    Faq,
  },
  props: {},
  data() {
    return {
      video: {},
      loading: false,
      experts: [],
      pricList: [],
      slides: [],
      settings: {
        apiKey: 'b8d77dd9-8477-4216-ad67-5074082c24a6',
        lang: 'ru_RU',
        coordorder: 'latlong',
        version: '2.1',
      },
      companyCategories: [],
      sliderOption: {
        direction: 'horizontal',
        loop: true,
        autoplay: {
          delay: 6000
        },
        pagination: {
          clickable: true,
        },
        breakpoints: {
          768: {
            slidesPerView: 1,
            spaceBetween: 10,
          },
          640: {
            slidesPerView: 1,
            spaceBetween: 6,
          },
          410: {
            slidesPerView: 1,
            spaceBetween: 10,
          },
          350: {
            slidesPerView: 1,
            spaceBetween: 10,
          },
          320: {
            slidesPerView: 1,
            spaceBetween: 10,
          },
        },
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
      },
      items: [
        {
          image: 'district',
          title: 'District Hokimiyat',
        },
        {
          image: 'bank',
          title: 'Banks',
        },
        {
          image: 'terminal',
          title: 'Terminal stations',
        },
        {
          image: 'railway',
          title: 'Railway stations',
        },
        {
          image: 'refrigerator',
          title: 'Refrigerators',
        },
        {
          image: 'government',
          title: 'Government branches',
        },
        {
          image: 'farmers',
          title: 'Farmers unions',
        },
        {
          image: 'supply',
          title: 'Supply points',
        },
      ],
      dataTitle: {
        en: 'Post',
        ru: 'Пост',
        uz: 'post',
        kaa: 'Post Kaa',
      },
      loader: false
    }
  },
  computed: {
    ...mapState({
      isLoggedIn: (state) => state.auth.loggedIn,
      currentUser: (state) => state.auth.user,
      userConnection: (state) => state.socket.userConnection,
      userConnectionStatus: (state) => state.socket.userConnectionStatus,
    }),
    ...mapGetters([
      'dataCourses',
      'dataUsers',
      'dataFaqs',
      // 'dataPartners',
      'getLocations',
      'dataNews',
    ]),
  },
  fetchOnServer: true,
  async fetch() {
    // this.$fetchState.pending = true
    await this.$store
      .dispatch('getSliders', {
        locale: this.$i18n.locale,
        fields: 'logo,subtitle,title,description,play_market_link,appstore_link,image',
        sort: 'id:desc'
      })
      .then(res => {
        this.slides = res

      })
      .catch((error) => {
        this.$sentry.captureException(error)
      })
    await this.$store
      .dispatch('getPricelists', {
        populate: 'district, district.localizations, product,product.localizations',
        fields: 'avg,min,max',
        locale: this.$i18n.locale,
        'pagination[page]': 1,
        'pagination[pageSize]': 12,
        filters: {
          max: {
            $gt: 0,
          },
          min: {
            $gt: 0,
          },
        },
      })
      .then((res) => {
        this.pricList = res.map((el) => {
          return {
            id: el.id,
            attributes: {
              ...el.attributes,
              district: {
                data: {
                  attributes: {
                    name:
                      el.attributes.district.data.attributes.locale === this.$i18n.locale
                        ? el.attributes.district.data.attributes.name
                        : this.checkProductLocalizations(
                            el.attributes.district.data.attributes.localizations.data,
                            this.$i18n.locale
                          ),
                  },
                },
              },
              product: {
                data: {
                  attributes: {
                    image: el.attributes.product.data.attributes.image,
                    name:
                      el.attributes.product.data.attributes.locale === this.$i18n.locale
                        ? el.attributes.product.data.attributes.name
                        : this.checkProductLocalizations(
                            el.attributes.product.data.attributes.localizations.data,
                            this.$i18n.locale
                          ),
                  },
                },
              },
            },
          }
        })
      })
      .catch((error) => {
        this.$sentry.captureException(error)
      })
    await this.$store
      .dispatch('getUsers', {
        link: '/users',
        query: {
          populate:
            'additionalinfo,additionalinfo.localization,consultantcategory, consultantcategory.localization,agrocultureareas, agrocultureareas.localization',
          locale: this.$i18n.locale,
          'filters[$and][0][confirmed]': true,
          'filters[$and][0][role][id]': 4,
          fields: 'avatar'
          // fields: 'avatar,middlename,name,surname,activitytypes,otherarea,additionalinfo,agrocultureareas,env_otherarea'
        },
      })
      .then((res) => {
        this.experts = res.users
      })
      .catch((error) => {
        this.$sentry.captureException(error)
      })
    //

    await this.$store
      .dispatch('getFaqs', {
        populate: '*',
        locale: this.$i18n.locale,
        fields: 'question,answer'
      })
      .catch((error) => {
        this.$sentry.captureException(error)
      })
    await this.$store
      .dispatch('getCourses', {
        populate: '*',
        locale: this.$i18n.locale,
        sort: ['createdAt:desc'],
        'pagination[page]': 1,
        'pagination[pageSize]': 3,
        filters: {
          status: {
            title: this.dataTitle[this.$i18n.locale],
          },
        },
        fields: 'image,thumbnail,publish_date,title,description'
      })
      .catch((error) => {
        this.$sentry.captureException(error)
      })
    // await this.getPartners()
    // await this.getCompanies()
    // await this.getCompanycategories()
    await this.getNews()
    // await this.getAbout()

  },
  methods: {
    async getNews () {
      try {
        const data =  await this.$store
          .dispatch('getNews', {
            populate: '*',
            locale: this.$i18n.locale,
            pagination: {
              page: 1,
              pageSize: 10
            },
            'sort[0][createdAt]': 'DESC',
            fields: 'title,description,createdAt,image'
          })
        return data
      } catch (err) {}
    },
    async getCompanies () {
     try {
       const data =   await this.$store
         .dispatch('getCompanies', {
           populate: '*',
           locale: this.$i18n.locale,
         })

         .catch((error) => {
           this.$sentry.captureException(error)
         })
           this.$store.dispatch('setCompanies', data)
       return data
     } catch (err) {}
    },
    async getCompanycategories () {
      try {
        const data = await this.$store
          .dispatch('getCompanycategories', {
            populate: '*',
            locale: this.$i18n.locale,
          })
          // .then((res) => {
            this.companyCategories = data
          // })
        return data
      } catch (err) {}
    },
    async getPartners () {
      try {
        const data=   await this.$store
          .dispatch('getPartners', {
            populate: '*',
            locale: this.$i18n.locale,
          })
        return data
      } catch (err)  {}
    },
    // async getAbout () {
    //   try {
    //     const data=  await this.$store
    //       .dispatch('getAbout', {
    //         locale: this.$i18n.locale
    //       })
    //       // .then(res => {
    //         console.log("getAbout", data)
    //       // })
    //       // .catch((error) => {
    //       //   this.$sentry.captureException(error)
    //       // })
    //
    //   } catch (err){
    //
    //   }
    // },
    prev() {
      this.$refs.swiper.$swiper.slidePrev()
    },
    next() {
      this.$refs.swiper.$swiper.slideNext()
    },
    checkProductLocalizations(item, lang) {
      let _ = item.find((el) => el.attributes.locale === lang).attributes.name
      return _
    },
    moveToAgriBusiness(item) {
      let _query = {
        category: item.id,
      }
      this.$router.push({
        path: this.localePath('/agri-business'),
        query: this.$tools.emptyObject(_query),
      })
    },
    openRegister() {
      this.$emit('close')
      this.$modal.show(
        registeModal,
        { status: 'register' },
        {
          height: 'auto',
          maxWidth: 700,
          width: window.innerWidth <= 650 ? window.innerWidth - 10 : 650,
          scrollable: true,
        }
      )
    },
  },

}
