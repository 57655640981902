import { render, staticRenderFns } from "./experts-swiper.vue?vue&type=template&id=0057d67a&scoped=true&"
import script from "./experts-swiper.vue?vue&type=script&lang=js&"
export * from "./experts-swiper.vue?vue&type=script&lang=js&"
import style0 from "./experts-swiper.vue?vue&type=style&index=0&id=0057d67a&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0057d67a",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Experts: require('/home/coozin/projects/jasilawil/jasilawil_front/components/Experts.vue').default})
