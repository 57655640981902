
import Experts from '../Experts.vue'
import { Swiper, SwiperSlide, Autoplay } from 'vue-awesome-swiper';
import 'swiper/swiper-bundle.css'
export default {
  name: 'ConsultantsSwiper',
  auth: false,
  props: {
    experts: Array
  },
  components: { Experts, 'swiper':Swiper, 'swiper-slide':SwiperSlide, Autoplay },
  mounted() {
  },
  data() {
    return {
      expertOption: {
        direction: 'horizontal',
        slideToClickedSlide: false,
        breakpoints: {
          1280: {
            slidesPerView: 3.5,
            spaceBetween: 16,
          },
          1024: {
            slidesPerView: 4.5,
            spaceBetween: 10,
          },
          768: {
            slidesPerView: 3,
            spaceBetween: 10,
          },
          640: {
            slidesPerView: 1.5,
            spaceBetween: 6,
          },
          410: {
            slidesPerView: 1.5,
            spaceBetween: 10,
          },
          350: {
            slidesPerView: 1.5,
            spaceBetween: 10,
          },
          320: {
            slidesPerView: 1.3,
            spaceBetween: 10,
          },
        },
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
      },
    }
  },
  methods: {
    prev() {
      this.$refs.swiper.$swiper.slidePrev()
    },
    next() {
      this.$refs.swiper.$swiper.slideNext()
    },
  },
}
