
export default {
  name: 'Faq',
  props: {
      data: Object
  },
  data() {
    return {
      isAnswerOpened: false,
    }
  },
  methods: {
    openAnswer() {
      this.isAnswerOpened = !this.isAnswerOpened
    },
  },
}
