
import Prices from '../Prices.vue'
export default {
  name: 'ConsultantsSwiper',
  components: { Prices },
  props: {
    prices: Array
  },
  data() {
    return {
      priceOption: {
        direction: 'horizontal',
        slideToClickedSlide: false,
        loop: false,
        autoplay: {
          delay: 2000,
        },
        pagination: {
          clickable: true,
        },
        breakpoints: {
          1280: {
            slidesPerView: 3.8,
            spaceBetween: 16,
          },
          1024: {
            slidesPerView: 4.3,
            spaceBetween: 10,
          },
          768: {
            slidesPerView: 3,
            spaceBetween: 10,
          },
          640: {
            slidesPerView: 1.3,
            spaceBetween: 6,
          },
          410: {
            slidesPerView: 1.3,
            spaceBetween: 10,
          },
          350: {
            slidesPerView: 1.3,
            spaceBetween: 10,
          },
          320: {
            slidesPerView: 1.3,
            spaceBetween: 10,
          },
        },
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
      },
    }
  },

  methods: {
    prev() {
      this.$refs.swiper.$swiper.slidePrev()
    },
    next() {
      this.$refs.swiper.$swiper.slideNext()
    },
  },
}
