import { render, staticRenderFns } from "./partners-swiper.vue?vue&type=template&id=38241037&scoped=true&"
import script from "./partners-swiper.vue?vue&type=script&lang=js&"
export * from "./partners-swiper.vue?vue&type=script&lang=js&"
import style0 from "./partners-swiper.vue?vue&type=style&index=0&id=38241037&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "38241037",
  null
  
)

export default component.exports