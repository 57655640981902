
export default {
  name: 'PartnersSwiper',
  auth: false,
  data() {
    return {
      partnerOption: {
        direction: 'horizontal',
        slideToClickedSlide: false,
        // loop: true,
        // autoplay: {
        //   delay: 2000,
        // },
        pagination: {
          clickable: true,
        },
        breakpoints: {
          1280: {
            slidesPerView: 5,
            spaceBetween: 16,
          },
          1024: {
            slidesPerView: 5,
            spaceBetween: 10,
          },
          768: {
            slidesPerView: 3,
            spaceBetween: 10,
          },
          640: {
            slidesPerView: 2.5,
            spaceBetween: 6,
          },
          410: {
            slidesPerView: 2.5,
            spaceBetween: 10,
          },
          350: {
            slidesPerView: 2.5,
            spaceBetween: 10,
          },
          320: {
            slidesPerView: 2.3,
            spaceBetween: 10,
          },
        },
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
      },
    }
  },
  props: {
    partners: Array
  },
  methods: {
    prev() {
      this.$refs.swiper.$swiper.slidePrev()
    },
    next() {
      this.$refs.swiper.$swiper.slideNext()
    },
  },
}
