
export default {
  name: 'VideoCard',
  props: {
    // eslint-disable-next-line vue/require-default-prop
    data: Object,
  },
  data() {
    return {}
  },
  methods: {
    toDetail() {
      // if (this.data.attributes.coursetype.data.id === 1) {
        this.$router.push({
          path: this.localePath(`/e-learning/${this.data.id}`),
        })
      // } else {
      //   this.$snotify.warning('Multiple courses')
      // }
    },
  },
}
