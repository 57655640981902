import { render, staticRenderFns } from "./new-swipers.vue?vue&type=template&id=0a67c6ca&scoped=true&"
import script from "./new-swipers.vue?vue&type=script&lang=js&"
export * from "./new-swipers.vue?vue&type=script&lang=js&"
import style0 from "./new-swipers.vue?vue&type=style&index=0&id=0a67c6ca&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0a67c6ca",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {News: require('/home/coozin/projects/jasilawil/jasilawil_front/components/News.vue').default})
